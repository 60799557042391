<template>
    <div>
         <!-- 没有儿子的菜单 -->
         <template v-if="hasOneShowingChild(item.children) || item.children==null">
            <el-menu-item :key="item.path" :index="item.children? item.children[0].path:item.path">
                <i :class="item.meta.icon"></i>
                <span slot="title">{{item.meta.name}}</span>
            </el-menu-item>
         </template>
		 
        <el-submenu v-else :index="item.name+item.path">
            <template slot="title">
              <i :class="item.meta.icon"></i>
              <span slot="title">{{item.meta.name}}</span>
            </template>
            <template v-for="child in item.children" >
                <navigation
                    v-if="child.children && child.children.length>0"
                    :key="child.path"
                    :item="child"
                 />
                <el-menu-item :key="child.path" :index="child.path">
                    <i :class="child.meta.icon"></i>
                    {{child.meta.name}}
                </el-menu-item>
            </template>
        </el-submenu>
    </div>
</template>

<script>
export default {
    name:'Navigation',
    data(){
        return{
        }
    },
    props:{
        item: {
            type: Object,
            required: true
        }
    },
    methods:{
        hasOneShowingChild(children = []){
            if (children.length === 1) {
                return true
            }
            return false
        }
    }
}
</script>

<style>

</style>